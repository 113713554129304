import React, { ReactElement, useContext, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Spin } from 'antd';

import { GlobalContext, IGlobalContext } from '../../GlobalContext';

import { IpcRepository } from '../../repositories/ipc-repository/IpcRepository';

import AlertError from '../../components/icons/AlertError';
import { RecentDeploysTable } from '../../components/recent-deploys-table';

import './styles.scss';

function DeployView(): ReactElement {
  const { getAccessTokenSilently } = useAuth0();

  const context: IGlobalContext = useContext(GlobalContext);
  const tableData = useState(context.tableData);

  const [groupName] = useState<string>(context.groupName);
  const [assetId, setAssetId] = useState<string>(context.assetId);

  const [deployError, setDeployError] = useState<boolean>(false);
  const [deployLoading, setDeployLoading] = useState<boolean>(false);
  const [deployErrorMessage, setDeployErrorMessage] = useState<string>('');

  const deployButtonDisabled: boolean =
    !assetId.length || !groupName.length || (deployLoading && !deployError);

  const handleErrorMessage: Function = (status: Number) => {
    const genericErrorMessage =
      'There was an issue with your request. Please try again or contact our Customer Support for further assistance.';

    const badRequestErrorMessage =
      'Your browser sent a request that this server could not understand. Please contact our Customer Support for further assistance.';

    const notFoundMessage =
      'Your browser sent a request that this server could not understand. Please contact our Customer Support for further assistance.';

    const unAuthErrorMessage =
      'You currently do not have access to this site. Please contact our Customer Support for further assistance.';

    const forbiddenErrorMessage =
      'You currently do not have access to this site. Please contact our Customer Support for further assistance.';

    const serverErrorMessage =
      'The page cannot be displayed. Please try again, or contact our Customer Support for further assistance.';

    const badGatewayMessage =
      'The server encountered a temporary error. Please try again, or contact our Customer Support for further assistance.';

    const serviceUnavailableErrorMessage =
      'This service is temporarily unavailable. Please try again later, or contact our Customer Support for further assistance.';

    const gatewayTimeoutErrorMessage =
      'The server took too long to respond. Please contact our Customer Support for further assistance.';

    switch (status) {
      case 400:
        return setDeployErrorMessage(badRequestErrorMessage);
      case 401:
        return setDeployErrorMessage(unAuthErrorMessage);
      case 403:
        return setDeployErrorMessage(forbiddenErrorMessage);
      case 404:
        return setDeployErrorMessage(notFoundMessage);
      case 500:
        return setDeployErrorMessage(serverErrorMessage);
      case 502:
        return setDeployErrorMessage(badGatewayMessage);
      case 503:
        return setDeployErrorMessage(serviceUnavailableErrorMessage);
      case 504:
        return setDeployErrorMessage(gatewayTimeoutErrorMessage);
      default:
        return setDeployErrorMessage(genericErrorMessage);
    }
  };

  const createNewDeploy: Function = async () => {
    const token = await getAccessTokenSilently();

    if (token) {
      const newAsset = await IpcRepository.createAssetDeploy(token, assetId);

      if (
        newAsset?.status !== 200 &&
        newAsset?.status !== 201 &&
        newAsset?.status !== 204
      ) {
        console.log('newasset', newAsset);
        handleErrorMessage(newAsset?.message);
        setDeployError(true);
      }
    }

    if (deployLoading) {
      setDeployLoading(false);
    }
  };

  const handleSubmit: Function = () => {
    if (deployError) {
      setDeployError(false);
    }

    setDeployErrorMessage('');
    setDeployLoading(true);
    createNewDeploy();
  };

  const displayedIpcInputs: ReactElement = (
    <div className='deploy-ipc'>
      <input
        type='text'
        placeholder='Enter Asset Id'
        disabled={deployLoading && !deployError}
        className='deploy-ipc-input'
        onChange={(e: React.FormEvent<HTMLInputElement>) =>
          setAssetId(e.currentTarget.value)
        }
      />
      <button
        className='deploy-ipc-button'
        onClick={() => handleSubmit()}
        disabled={deployButtonDisabled}
      >
        {deployLoading && !deployError && <Spin size='small' style={{ margin: 0 }} />}
        Deploy
      </button>
      {deployError && (
        <p className='deploy-ipc-error-message'>
          <AlertError style={{ marginRight: '10px' }} />
          {deployErrorMessage}
        </p>
      )}
    </div>
  );

  return (
    <section className='deploy--deploy-ipc-container'>
      <p className='ipc-header'>New Deployment</p>
      {displayedIpcInputs}
      <RecentDeploysTable tableData={tableData as any} />
    </section>
  );
}

export default DeployView;
