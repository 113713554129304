export const formatDate: Function = (date: string) => {
  const formattedDate = new Date(date);
  const year = formattedDate.getFullYear().toString();

  let month = (formattedDate.getMonth() + 1).toString();
  let day = formattedDate.getDate().toString();

  if (month.length === 1) {
    month = `0${month}`;
  }

  if (day.length === 1) {
    day = `0${day}`;
  }

  return `${month} - ${day} - ${year}`;
};
