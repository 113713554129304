import { ReactElement } from 'react';

function Checkmark(): ReactElement {
  return (
    <svg width='17' height='12.551' viewBox='0 0 17 12.551'>
      <g id='Group_254' transform='translate(-663.5 -308.75)'>
        <path
          id='done_FILL0_wght400_GRAD0_opsz48'
          d='M13.205,23.751,7.7,18.246l1.057-1.057,4.449,4.449L22.643,12.2,23.7,13.257Z'
          transform='translate(656.3 297.05)'
          fill='#2ef05b'
          stroke='#2ef05b'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1'
        />
      </g>
    </svg>
  );
}

export default Checkmark;
