import { ReactElement } from 'react';

import './../styles.scss';

const Table = ({
  tableHeaderRow,
  displayedTableRows,
}: {
  tableHeaderRow: any;
  displayedTableRows: ReactElement[];
}) => {
  return (
    <div className='table'>
      {tableHeaderRow}
      {displayedTableRows}
    </div>
  );
};

export default Table;
