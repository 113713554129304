import { Spin } from 'antd';
import { useEffect, useState } from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';
import { Header } from 'axil-shared-components-library';

import { useAuth0 } from '@auth0/auth0-react';

import { GlobalContext } from './GlobalContext';

import { IpcRepository } from './repositories/ipc-repository/IpcRepository';

import IpcList from './views/list';
import DeployView from './views/deploy';
import NotFound from './views/not-found';
import HomeRedirect from './views/home-redirect';

import Container from './components/container';
import IpcIcon from './components/icons/IpcIcon';
import IpcLogo from './components/icons/IpcLogo';
import DeployIcon from './components/icons/DeployIcon';
import { IIpcTableRow } from './components/deploys-table';
import IpcLogoSmall from './components/icons/IpcLogoSmall';
import { SideMenuOption, SideMenu } from './components/side-menu';

import './index.scss';

import './App.scss';

function App() {
  // auth
  const [accessToken, setToken] = useState<string>('');
  const {
    user,
    isLoading,
    isAuthenticated,
    logout,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  // header
  const darkMode: boolean = true;
  const userEmail: string = user?.email || 'loading...';

  // side menu
  const sideMenuOpen: boolean = true;
  const sideMenuOptions: SideMenuOption[] = [
    {
      title: 'Deploy',
      icon: <DeployIcon />,
      redirect: 'deploy',
    },
    {
      title: 'Industrial PCs',
      icon: <IpcIcon />,
      redirect: 'list',
    },
  ];

  // deployed asset list
  const [realData, setRealData] = useState<IIpcTableRow[]>([]);
  const [assetsChecked, setAssetsChecked] = useState<boolean>(false);

  // check auth and get assets
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }

    const getTokenAndAssets = async () => {
      const token = await getAccessTokenSilently();

      if (token) {
        setToken(token);

        const assetsResponse = await IpcRepository.getAssets(token);

        if (assetsResponse?.ipcAssets) {
          setRealData(assetsResponse?.ipcAssets);
          setAssetsChecked(!assetsChecked);
        }
      }
    };

    if (!isLoading && isAuthenticated) {
      getTokenAndAssets();
    }
  }, [isAuthenticated, isLoading]);

  return (
    <GlobalContext.Provider
      value={{
        assetId: '',
        groupName: 'EED_Group',
        user: user,
        token: accessToken,
        darkMode: darkMode,
        tableData: realData,
      }}
    >
      <Container className='app'>
        <Header darkMode={darkMode} email={userEmail} logout={() => logout()} />
        <Container flex>
          <SideMenu
            options={sideMenuOptions}
            sideMenuOpen={sideMenuOpen}
            largeLogo={<IpcLogo />}
            smallLogo={<IpcLogoSmall />}
          />
          {assetsChecked ? (
            <Routes>
              <Route path='/' element={<HomeRedirect />} />
              <Route path='/list' element={<IpcList />} />
              <Route path='/deploy' element={<DeployView />} />
              <Route path='*' element={<NotFound />} />
            </Routes>
          ) : (
            <Container className='loading-spinner'>
              <Spin size='large' />
            </Container>
          )}
          <Outlet />
        </Container>
      </Container>
    </GlobalContext.Provider>
  );
}

export default App;
