import { ReactElement } from 'react';

import './styles.scss';

function NotFound(): ReactElement {
  return (
    <section className='not-found--not-found-container'>
      <p className='not-found-text'>The page you requested does not exist.</p>
      <p className='not-found-face'>{':('}</p>
    </section>
  );
}

export default NotFound;
