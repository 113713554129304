import { formatDate } from '../../../utils/formatDate';

import { IIpcTableRow } from '../../deploys-table';
import { DownloadComponent } from '../../download';

export const GenerateRow = (deploy: IIpcTableRow) => {
  return (
    <div key={deploy?.netsuiteId?.toString()} className='table-row dark-hover'>
      <div className='table-row-item-container'>
        <p className='table-row-item'>{deploy?.netsuiteId.toString()}</p>
      </div>
      <div className='table-row-item-container'>
        <p className='table-row-item'>{formatDate(deploy?.createdDate)}</p>
      </div>
      <div className='table-row-item-container'>
        <DownloadComponent deploy={deploy} />
      </div>
    </div>
  );
};
