const isProd = process.env.REACT_APP_BUILD_ENV === 'production';
const devPrefix = !isProd ? 'dev-' : '';
const localSuffix = process.env.REACT_APP_IS_LOCAL === 'true' ? '/local' : '';
const baseUrl = `https://${devPrefix}api.axil.ai${localSuffix}/industrial-pc`;

export class IpcRepository {
  static async getAssets(token: string) {
    try {
      const res = await fetch(`${baseUrl}/assets`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const response = await res.json();
      return response;
    } catch (error) {
      console.log('error ', error);
      return error;
    }
  }

  static async getAsset(token: string, netsuiteId: string) {
    try {
      const res = await fetch(`${baseUrl}/assets/${netsuiteId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const response = await res.json();
      return response;
    } catch (error) {
      console.log('error ', error);
      return error;
    }
  }

  static async createAssetDeploy(token: string, assetId: string) {
    const group = { thingGroupName: 'EED_Group' };
    const id = Number(assetId);

    try {
      const res = await fetch(`${baseUrl}/assets/${id}/files`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(group),
      });

      const response = await res.json();
      return response;
    } catch (error) {
      console.log('error ', error);
      return error;
    }
  }
}
