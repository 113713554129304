import { ReactElement } from 'react';

export default function IpcLogo(): ReactElement {
  return (
    <svg width='187.22' height='13.117' viewBox='0 0 187.22 13.117'>
      <defs>
        <clipPath id='clip-path'>
          <rect id='Rectangle_656' width='187.22' height='13.117' fill='#fff' />
        </clipPath>
      </defs>
      <g id='Group_260' transform='translate(0 0)'>
        <g id='Group_244' transform='translate(0 0)' clipPath='url(#clipPath)'>
          <path
            id='Path_233'
            d='M506.185,9.764h-6.863v3.353h-4.039V6.412h11.294c1.255,0,1.98-.608,1.98-1.51,0-.921-.725-1.49-1.98-1.49H495.283L497.969,0H506.2c4.059,0,6.431,2,6.431,4.921,0,2.9-2.373,4.843-6.451,4.843'
            transform='translate(-344.074 0)'
            fill='#f05921'
          />
          <path
            id='Path_234'
            d='M32.786,14.287,19.664,5.1v9.184H17.122V1.684h2.524l12.013,8.629V1.684h2.56v12.6Z'
            transform='translate(-11.894 -1.17)'
            fill='#fff'
          />
          <path
            id='Path_235'
            d='M80.332,14.287V1.684H92.166c3.652,0,4.762,1.235,4.762,3.724v5.281c0,2.363-1.11,3.6-4.762,3.6Zm13.66-8.88c0-1.235-.376-1.45-1.826-1.45H83.25V12h8.916c1.45,0,1.826-.215,1.826-1.343Z'
            transform='translate(-55.807 -1.17)'
            fill='#fff'
          />
          <path
            id='Path_236'
            d='M154.644,1.684h2.918v9.56c0,1.808-1.11,3.044-4.046,3.044h-8.038c-2.936,0-4.046-1.235-4.046-3.044V1.684h2.918v9.578c0,.519.394.734,1.128.734h8.038c.734,0,1.128-.215,1.128-.734Z'
            transform='translate(-98.253 -1.17)'
            fill='#fff'
          />
          <path
            id='Path_237'
            d='M200.89,14.287V12H212.1c.823,0,1.146-.143,1.146-.752V9.436c0-.573-.34-.77-1.146-.77H204.56c-2.954,0-4.082-1.235-4.082-3.061V4.745c0-1.826,1.128-3.061,4.082-3.061h10.9V3.958h-10.9c-.77,0-1.164.2-1.164.77v.895c0,.537.34.77,1.164.77H212.1c2.954,0,4.082,1.236,4.082,3.061v1.755c0,1.826-1.128,3.079-4.082,3.079Z'
            transform='translate(-139.272 -1.17)'
            fill='#fff'
          />
          <path
            id='Path_238'
            d='M260.572,14.287V3.957h-6.5V1.684h15.79V3.957h-6.355v10.33Z'
            transform='translate(-176.505 -1.17)'
            fill='#fff'
          />
          <path
            id='Path_239'
            d='M322.344,14.287l-2.811-5.6h-6.015v5.6H310.6V1.683h10.957c2.936,0,4.064,1.235,4.064,3.044v.913c0,1.5-.716,2.7-2.972,3.008l2.829,5.639Zm.34-9.6c0-.5-.394-.734-1.128-.734h-8.038V6.409h8.038c.77,0,1.128-.143,1.128-.752Z'
            transform='translate(-215.774 -1.169)'
            fill='#fff'
          />
          <rect
            id='Rectangle_655'
            width='2.918'
            height='12.603'
            transform='translate(111.724 0.514)'
            fill='#fff'
          />
          <path
            id='Path_240'
            d='M395.427,14.287l-2.166-3.366h-8.289l-2.148,3.366h-3.151l8.4-12.6h2.148l8.343,12.6Zm-6.266-9.775-2.667,4.118h5.3Z'
            transform='translate(-263.76 -1.169)'
            fill='#fff'
          />
          <path
            id='Path_241'
            d='M445.287,14.287V1.684h2.954V12h9.972v2.292Z'
            transform='translate(-309.342 -1.17)'
            fill='#fff'
          />
          <path
            id='Path_242'
            d='M574.331,9.745l-2.608,3.373h-6.588c-4.549,0-7.9-2.883-7.9-6.686,0-3.843,3.353-6.431,7.9-6.431h9.2l-2.608,3.412h-6.588c-2.2,0-3.843,1.314-3.843,3.2,0,1.863,1.627,3.137,3.843,3.137Z'
            transform='translate(-387.111 0)'
            fill='#f05921'
          />
          <path
            id='Path_243'
            d='M1.459,1.684,0,3.543V14.287H2.918V1.684Z'
            transform='translate(0 -1.17)'
            fill='#fff'
          />
        </g>
      </g>
    </svg>
  );
}
