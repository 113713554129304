import React, { ReactElement, useContext, useState } from 'react';

import { IGlobalContext, GlobalContext } from '../../GlobalContext';

import DeploysTable, { IIpcTableRow } from '../../components/deploys-table';

import './styles.scss';

function IpcList(): ReactElement {
  const context: IGlobalContext = useContext(GlobalContext);
  const [tableData, setFilteredTableData] = useState<IIpcTableRow[]>(
    context.tableData
  );

  const handleSearch: Function = (input: string) => {
    const newFilteredData: IIpcTableRow[] = tableData?.filter(
      (deploy: IIpcTableRow) => {
        return deploy?.netsuiteId?.toString().includes(input);
      }
    );

    return setFilteredTableData(newFilteredData);
  };

  const handleInput: Function = (input: string) => {
    if (!input.length) {
      return setFilteredTableData(context.tableData);
    }

    handleSearch(input);
  };

  const newDeployRow: ReactElement = (
    <div className='search-ipc-container'>
      <input
        type='text'
        placeholder='Filter Netsuite Id'
        className='search-ipc-input'
        onChange={(e: React.FormEvent<HTMLInputElement>) =>
          handleInput(e.currentTarget.value)
        }
      />
    </div>
  );

  return (
    <section className='list--ipc-list-container'>
      <p className='ipc-header'>Industrial PCs</p>
      {newDeployRow}
      <DeploysTable tableData={tableData} />
    </section>
  );
}

export default IpcList;
