import { ReactElement } from 'react';

export default function RightArrowWhite(): ReactElement {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      transform='rotate(270)'
      style={{ marginRight: 8 }}
    >
      <path id='Path_245' d='M0,0H24V24H0Z' fill='none' />
      <path
        id='Path_246'
        d='M12,8,6,14l1.41,1.41L12,10.83l4.59,4.58L18,14Z'
        fill='#fff'
      />
    </svg>
  );
}
