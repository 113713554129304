import { ReactElement } from 'react';

export default function DownloadIcon(): ReactElement {
  return (
    <svg id='Component_90_50' width='16' height='16' viewBox='0 0 16 16'>
      <path
        id='file_download_FILL0_wght400_GRAD0_opsz48'
        d='M16,20.175,11.175,15.35l1.075-1.075,3,3V8h1.5v9.275l3-3,1.075,1.075ZM9.5,24A1.538,1.538,0,0,1,8,22.5V18.925H9.5V22.5h13V18.925H24V22.5A1.538,1.538,0,0,1,22.5,24Z'
        transform='translate(-8 -8)'
        fill='#fff'
      />
    </svg>
  );
}
