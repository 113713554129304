import { ReactElement } from 'react';

export default function SortIcon(): ReactElement {
  return (
    <svg width='10.292' height='16' viewBox='0 0 10.292 16'>
      <g id='Group_326' transform='translate(-382 -185)'>
        <path
          id='Polygon_1'
          d='M5.146,0l5.146,6.737H0Z'
          transform='translate(392.292 201) rotate(180)'
          fill='#666'
        />
        <path
          id='Polygon_2'
          d='M5.146,0l5.146,6.737H0Z'
          transform='translate(382 185)'
          fill='#666'
        />
      </g>
    </svg>
  );
}
