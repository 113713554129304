import { createContext } from 'react';
import { User } from '@auth0/auth0-react';

import { IIpcTableRow } from './components/deploys-table';

export interface IGlobalContext {
  tableData: IIpcTableRow[];
  user: User | undefined;
  darkMode: boolean;
  assetId: string;
  groupName: string;
  token: string;
}

const defaultState = {
  tableData: [],
  user: {},
  darkMode: false,
  assetId: '',
  groupName: '',
  token: '',
};

export const GlobalContext = createContext<IGlobalContext>(defaultState);
