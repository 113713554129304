import { ReactElement } from 'react';

import { IIpcTableRow } from '../deploys-table';

import Table from '../tables/table';
import { GenerateRow } from '../tables/table-row/GenerateRow';

import './styles.scss';

export const RecentDeploysTable = ({
  tableData,
}: {
  tableData: IIpcTableRow[][];
}): ReactElement => {
  const today = Date.now();
  const showDaysAgo: Number = 30;
  const todayReadable: Date = new Date(today);

  const daysBetween: Function = (d1: Date, d2: Date) => {
    const diff = Math.abs(d1.getTime() - d2.getTime());
    return diff / (1000 * 60 * 60 * 24);
  };

  const filteredData: (IIpcTableRow | undefined)[] = tableData?.[0]?.map(
    (deploy: IIpcTableRow | undefined) => {
      const id = deploy?.netsuiteId;
      const entryDate = deploy?.createdDate;

      const entryDateFormatted = new Date(Number(entryDate));
      const daysPassed = daysBetween(entryDateFormatted, todayReadable);

      if (Math.floor(daysPassed) < showDaysAgo) {
        return deploy;
      }
    }
  );

  const tableHeaderRow: ReactElement = (
    <div className='table-header-row' key='table-header-key'>
      <div className='table-row-item-container'>
        <p className='table-row-item'>Asset</p>
      </div>
      <div className='table-row-item-container'>
        <p className='table-row-item'>Date Created</p>
      </div>
      <div className='table-row-item-container'>
        <p className='table-row-item'>Download</p>
      </div>
    </div>
  );

  const displayedTableRows: ReactElement[] = [];

  const descendingDates: (IIpcTableRow | undefined)[] = [...filteredData].sort(
    (deployA: IIpcTableRow | undefined, deployB: IIpcTableRow | undefined) =>
      Number(deployA?.createdDate) > Number(deployB?.createdDate) ? -1 : 1
  );

  descendingDates.map((deploy: IIpcTableRow | undefined) => {
    if (deploy?.createdDate) {
      const row: ReactElement = GenerateRow(deploy);
      displayedTableRows.push(row);
    }
  });

  return (
    <section className='recent-deploys-table--recently-added-container'>
      <p className='recently-added-header'>Recently Added</p>
      <Table
        tableHeaderRow={tableHeaderRow}
        displayedTableRows={displayedTableRows}
      />
    </section>
  );
};
