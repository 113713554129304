import { ReactElement } from 'react';

export default function IpcLogoSmall(): ReactElement {
  return (
    <svg width='42.22' height='13.117' viewBox='0 0 42.22 13.117'>
      <g id='Group_261' transform='translate(0 0)'>
        <g id='Group_252' transform='translate(0 0)'>
          <path
            id='Path_233'
            d='M506.185,9.764h-6.863v3.353h-4.039V6.412h11.294c1.255,0,1.98-.608,1.98-1.51,0-.921-.725-1.49-1.98-1.49H495.283L497.969,0H506.2c4.059,0,6.431,2,6.431,4.921,0,2.9-2.373,4.843-6.451,4.843'
            transform='translate(-489.074 0)'
            fill='#f05921'
          />
          <path
            id='Path_242'
            d='M574.331,9.745l-2.608,3.373h-6.588c-4.549,0-7.9-2.883-7.9-6.686,0-3.843,3.353-6.431,7.9-6.431h9.2l-2.608,3.412h-6.588c-2.2,0-3.843,1.314-3.843,3.2,0,1.863,1.627,3.137,3.843,3.137Z'
            transform='translate(-532.111 0)'
            fill='#f05921'
          />
          <path
            id='Path_243'
            d='M1.459,1.684,0,3.543V14.287H2.918V1.684Z'
            transform='translate(0 -1.17)'
            fill='#fff'
          />
        </g>
      </g>
    </svg>
  );
}
