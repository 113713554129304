import { ReactElement } from 'react';

import './styles.scss';

interface IContainerProps {
  children: any;
  flex?: boolean;
  className?: string;
}

function Container(props: IContainerProps): ReactElement {
  const children: any = props.children;
  const flex: boolean | undefined = props.flex;
  const className: string | undefined = props.className;

  return (
    <div className={`container ${flex && 'flex'} ${className}}`}>
      {children}
    </div>
  );
}

export default Container;
