import { ReactElement } from "react";

export default function DeployIcon(): ReactElement {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24'>
      <path id='Path_210' d='M0,0H24V24H0Z' fill='none' />
      <path
        id='Path_211'
        d='M19,19H5V5h7V3H5A2,2,0,0,0,3,5V19a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V12H19ZM14,3V5h3.59L7.76,14.83l1.41,1.41L19,6.41V10h2V3Z'
        fill='#fff'
      />
    </svg>
  );
}
