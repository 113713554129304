import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { Spin } from 'antd';

import 'antd/dist/antd.css';
import './index.scss';

function HomeRedirect() {
  const navigate = useNavigate();
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  const initialRoute: string = 'deploy';

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }

    if (!isLoading && isAuthenticated) {
      navigate(initialRoute);
    }
  }, [isAuthenticated, isLoading]);

  return (
    <section className='ipc-fullscreen-loader'>
      <Spin />
    </section>
  );
}

export default HomeRedirect;
