import { ReactElement, useState } from 'react';
import { NavLink } from 'react-router-dom';

import LeftArrowWhite from '../icons/LeftArrowWhite';
import RightArrowWhite from '../icons/RightArrowWhite';

import './styles.scss';

export interface SideMenuOption {
  icon: ReactElement;
  title: string;
  redirect: string;
}

export function SideMenu({
  sideMenuOpen,
  smallLogo,
  largeLogo,
  options,
}: {
  sideMenuOpen: boolean;
  smallLogo: ReactElement;
  largeLogo: ReactElement;
  options: SideMenuOption[];
}): ReactElement {
  const initialOpenValue: boolean =
    localStorage.getItem('sideMenuOpen') !== null
      ? JSON.parse(localStorage.getItem('sideMenuOpen') as string)
      : sideMenuOpen;

  const [menuOpen, toggleMenuOpen] = useState<boolean>(initialOpenValue);

  const setLocalStorageAndToggle = (value: boolean) => {
    localStorage.setItem('sideMenuOpen', JSON.stringify(value));
    toggleMenuOpen(value);
  };

  const largeDisplayedOptions: ReactElement[] = [];
  const smallDisplayedOptions: ReactElement[] = [];

  const displayedLogo: ReactElement = menuOpen ? largeLogo : smallLogo;
  const displayedArrow: ReactElement = menuOpen ? (
    <RightArrowWhite />
  ) : (
    <LeftArrowWhite />
  );

  options.map((option: SideMenuOption) => {
    const title: string = option.title;
    const icon: ReactElement = option.icon;
    const redirect: string = option.redirect;

    const openMenuOptionId: string = `${title}-large`;
    const closedMenuOptionId: string = `${title}-small`;

    const menuOptionOpen: ReactElement = (
      <NavLink
        key={title}
        title={title}
        to={redirect}
        id={openMenuOptionId}
        className={({ isActive }) =>
          isActive ? 'displayed-option-active' : 'displayed-option'
        }
      >
        <div className='displayed-option-icon'>{icon}</div>
        <p className='displayed-option-title'>{title}</p>
      </NavLink>
    );

    const menuOptionClosed: ReactElement = (
      <NavLink
        key={title}
        title={title}
        to={redirect}
        id={closedMenuOptionId}
        className={({ isActive }) =>
          isActive
            ? 'displayed-option-closed-active'
            : 'displayed-option-closed'
        }
      >
        <div className='displayed-option-icon displayed-option-icon-closed'>
          {icon}
        </div>
      </NavLink>
    );

    largeDisplayedOptions.push(menuOptionOpen);
    smallDisplayedOptions.push(menuOptionClosed);
  });

  const sideMenuContainerClass: string = menuOpen
    ? 'side-menu-container'
    : 'side-menu-container-closed';

  const sideMenuHeaderClass: string = menuOpen
    ? 'side-menu-header'
    : 'side-menu-header-closed';

  const sideMenuContentClass: string = menuOpen
    ? 'side-menu-content'
    : 'side-menu-content-closed';

  const sideMenuToggleClass: string = menuOpen
    ? 'side-menu-toggle'
    : 'side-menu-toggle-closed';

  const displayedSideMenuOptions: ReactElement[] = menuOpen
    ? largeDisplayedOptions
    : smallDisplayedOptions;

  return (
    <section className={`side-menu--${sideMenuContainerClass}`}>
      <div className='side-menu'>
        <section className={sideMenuHeaderClass}>{displayedLogo}</section>
        <section className={sideMenuContentClass}>
          <div className='side-menu-options'>{displayedSideMenuOptions}</div>
          <div
            className={sideMenuToggleClass}
            onClick={() => setLocalStorageAndToggle(!menuOpen)}
          >
            {displayedArrow}
          </div>
        </section>
      </div>
    </section>
  );
}
