const isProd = process.env.REACT_APP_BUILD_ENV === 'production';
const devPrefix = !isProd ? 'dev-' : '';
const localSuffix = process.env.REACT_APP_IS_LOCAL === 'true' ? '/local' : '';
const baseUrl = `https://${devPrefix}api.axil.ai${localSuffix}/databook`;

export class DatabookRepository {
  static async checkDatabookDownload(token: string, netsuiteId: Number) {
    // body is expected to change if we want to email
    const body = {
      databookId: `${netsuiteId}`,
      action: 'download',
      params: {
        netsuiteId,
      },
    };

    try {
      const res = await fetch(`${baseUrl}/industrial-pc`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      const response = await res.json();
      return response;
    } catch (error) {
      console.log('error ', error);
      return error;
    }
  }

  static async getDatabook(token: string, netsuiteId: Number) {
    try {
      const res = await fetch(`${baseUrl}/industrial-pc/${netsuiteId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      const response = await res.json();
      return response;
    } catch (error) {
      console.log('error ', error);
      return error;
    }
  }

  static async getDatabookNoJSON(token: string, netsuiteId: Number) {
    try {
      return await fetch(`${baseUrl}/industrial-pc/${netsuiteId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      console.log('error ', error);
      return error;
    }
  }
}
