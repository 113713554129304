import { ReactElement } from 'react';

function AlertError(restOfProps: any): ReactElement {
  return (
    <svg
      {...restOfProps}
      id='Group_327'
      width='16'
      height='13.818'
      viewBox='0 0 16 13.818'
    >
      <g id='Group_316' data-name='Group 316'>
        <path
          id='Path_255'
          d='M9,4.9l5.476,9.462H3.524L9,4.9M9,2,1,15.818H17L9,2Z'
          transform='translate(-1 -2)'
          fill='#f01628'
        />
        <path
          id='Path_256'
          d='M12.455,16H11v1.455h1.455Z'
          transform='translate(-3.727 -5.818)'
          fill='#f01628'
        />
        <path
          id='Path_257'
          d='M12.455,10H11v3.637h1.455Z'
          transform='translate(-3.727 -4.182)'
          fill='#f01628'
        />
      </g>
    </svg>
  );
}

export default AlertError;
