import { ReactElement, useContext, useState } from 'react';
import { Spin } from 'antd';

import { GlobalContext, IGlobalContext } from '../../GlobalContext';

import { DatabookRepository } from '../../repositories/databook-repository/DatabookRepository';

import AlertError from '../icons/AlertError';
import Checkmark from '../icons/CheckmarkIcon';
import DownloadIcon from '../icons/DownloadIcon';

import { handleDownload } from '../../utils/handleDownload';
import { IIpcTableRow } from '../deploys-table';

// casting (deploy: any) to 'IIpcTableRow' needs to be worked out...
export const DownloadComponent: Function = (deploy: any) => {
  const context: IGlobalContext = useContext(GlobalContext);
  const [accessToken] = useState<string>(context.token);

  const [downloadError, setDownloadError] = useState<boolean>(false);
  const [downloadQueued, setDownloadQueued] = useState<boolean>(false);
  const [downloadSuccess, setDownloadSuccess] = useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);

  const [downloadStatus, setDownloadStatus] = useState<string>('Processing');
  const [downloadErrorMessage, setDownloadErrorMessage] = useState<string>('');

  const handleErrorMessage: Function = (status: Number) => {
    const badRequestErrorMessage =
      'Your browser sent a request that this server could not understand. Please contact our Customer Support for further assistance.';

    const notFoundMessage =
      'Your browser sent a request that this server could not understand. Please contact our Customer Support for further assistance.';

    const unAuthErrorMessage =
      'You currently do not have access to this site. Please contact our Customer Support for further assistance.';

    const forbiddenErrorMessage =
      'You currently do not have access to this site. Please contact our Customer Support for further assistance.';

    const serverErrorMessage =
      'The page cannot be displayed. Please try again, or contact our Customer Support for further assistance.';

    const badGatewayMessage =
      'The server encountered a temporary error. Please try again, or contact our Customer Support for further assistance.';

    const serviceUnavailableErrorMessage =
      'This service is temporarily unavailable. Please try again later, or contact our Customer Support for further assistance.';

    const gatewayTimeoutErrorMessage =
      'The server took too long to respond. Please contact our Customer Support for further assistance.';

    switch (status) {
      case 400:
        return setDownloadErrorMessage(badRequestErrorMessage);
      case 401:
        return setDownloadErrorMessage(unAuthErrorMessage);
      case 403:
        return setDownloadErrorMessage(forbiddenErrorMessage);
      case 404:
        return setDownloadErrorMessage(notFoundMessage);
      case 500:
        return setDownloadErrorMessage(serverErrorMessage);
      case 502:
        return setDownloadErrorMessage(badGatewayMessage);
      case 503:
        return setDownloadErrorMessage(serviceUnavailableErrorMessage);
      case 504:
        return setDownloadErrorMessage(gatewayTimeoutErrorMessage);
      default:
        break;
    }
  };

  // remove when deploy at top is typed correctly
  const usableDeploy: IIpcTableRow = deploy.deploy;

  const handleClick: Function = async () => {
    // clear fields and set loading
    setDownloadSuccess(false);
    setDownloadError(false);
    setDownloadQueued(false);

    setDownloadLoading(true);

    // check status
    const checkedDatabook = await DatabookRepository.checkDatabookDownload(
      accessToken,
      usableDeploy.netsuiteId,
    );

    const currentStatus: string = checkedDatabook?.databookStatus;

    // set displayed status and handle response
    if (currentStatus?.length) {
      setDownloadStatus(currentStatus);

      switch (currentStatus) {
        case 'Errored':
          setDownloadQueued(false);
          setDownloadSuccess(false);
          setDownloadLoading(false);

          const erroredDatabook: any = await DatabookRepository.getDatabookNoJSON(
            accessToken,
            usableDeploy.netsuiteId,
          );

          handleErrorMessage(erroredDatabook?.status);
          setDownloadError(true);
          break;
        case 'Completed':
          const databook: any = await DatabookRepository.getDatabook(
            accessToken,
            usableDeploy.netsuiteId,
          );

          // handle errors if no url
          if (!databook.databookUrl?.length) {
            setDownloadQueued(false);
            setDownloadSuccess(false);
            setDownloadLoading(false);

            const databookNoJson: any = await DatabookRepository.getDatabookNoJSON(
              accessToken,
              usableDeploy.netsuiteId,
            );

            handleErrorMessage(databookNoJson?.status);
            setDownloadError(true);
          }

          // download if url
          if (databook?.databookUrl?.length) {
            setDownloadQueued(false);
            setDownloadError(true);
            setDownloadLoading(false);

            setDownloadSuccess(false);
            window.open(databook?.databookUrl, '_blank');
          }

          break;
        case 'Queued' || 'Processing':
          setDownloadError(false);
          setDownloadLoading(false);
          setDownloadSuccess(false);

          setDownloadQueued(true);

          let tryAgain = true;

          if (tryAgain) {
            setTimeout(async () => {
              const anotherCheck = await DatabookRepository.checkDatabookDownload(
                accessToken,
                usableDeploy.netsuiteId,
              );

              setDownloadStatus(anotherCheck?.databookStatus);

              if (anotherCheck?.databookStatus === 'Completed') {
                tryAgain = false;

                setDownloadError(false);
                setDownloadQueued(false);
                setDownloadLoading(false);

                setDownloadSuccess(true);
              }

              if (anotherCheck?.databookStatus === 'Errored') {
                tryAgain = false;

                setDownloadSuccess(false);
                setDownloadLoading(false);
                setDownloadQueued(false);
                setDownloadErrorMessage(anotherCheck?.message);

                setDownloadError(true);
              }

              return;
            }, 5000);
          }

          break;
        default:
          break;
      }
    }
  };

  const downloadColumn: ReactElement = (
    <p className='table-row-item' onClick={() => handleClick()}>
      <span className='light-hover'>
        <DownloadIcon />
      </span>
    </p>
  );

  const statusColumn: ReactElement = (
    <div className='table-row-item'>
      {downloadLoading && !downloadError && !downloadSuccess && !downloadQueued && (
        <Spin size='small' />
      )}

      {downloadQueued && (
        <div className='download-status'>
          <Spin size='small' />
          <p className='table-row-queued-message'>{downloadStatus}.</p>
        </div>
      )}

      {downloadError && (
        <div className='download-status'>
          <AlertError />
          <p className='table-row-error-message'>{downloadErrorMessage}</p>
        </div>
      )}

      {downloadSuccess && (
        <div className='download-status'>
          <Checkmark />
          <p className='table-row-success-message'>{downloadStatus}</p>
        </div>
      )}
    </div>
  );

  const displayedColumn: ReactElement =
    !downloadLoading && !downloadError && !downloadSuccess && !downloadQueued
      ? downloadColumn
      : statusColumn;

  return displayedColumn;
};
