import { ReactElement, useState } from 'react';

import Table from '../tables/table';
import { GenerateRow } from '../tables/table-row/GenerateRow';

import SortIcon from '../icons/SorIcon';
import SortUpIcon from '../icons/SortUpIcon';
import SortDownIcon from '../icons/SortDownIcon';

import './styles.scss';

export interface IIpcTableRow {
  netsuiteId: Number;
  createdDate: Number;
}

const DeploysTable = ({
  tableData,
}: {
  tableData: IIpcTableRow[];
}): ReactElement => {
  const [filterOrder, setFilterOrder] = useState<string>('ascendingNumber');

  let filterDateIcon: ReactElement = <SortIcon />;
  let filterNumberIcon: ReactElement = <SortIcon />;

  const ascendingNetsuiteIdData: IIpcTableRow[] = [...tableData].sort(
    (deployA: IIpcTableRow, deployB: IIpcTableRow) =>
      deployA.netsuiteId > deployB.netsuiteId ? -1 : 1
  );
  const descendingNetsuiteIdData: IIpcTableRow[] = [...tableData].sort(
    (deployA: IIpcTableRow, deployB: IIpcTableRow) =>
      deployB.netsuiteId > deployA.netsuiteId ? -1 : 1
  );
  const ascendingAssetDateData: IIpcTableRow[] = [...tableData].sort(
    (deployA: IIpcTableRow, deployB: IIpcTableRow) =>
      deployA.createdDate > deployB.createdDate ? -1 : 1
  );
  const descendingAssetDateData: IIpcTableRow[] = [...tableData].sort(
    (deployA: IIpcTableRow, deployB: IIpcTableRow) =>
      deployB.createdDate > deployA.createdDate ? -1 : 1
  );

  const filterNumber: Function = () => {
    const newFilter: string =
      filterOrder === 'ascendingNumber'
        ? 'descendingNumber'
        : 'ascendingNumber';

    setFilterOrder(newFilter);
  };

  const filterDate: Function = () => {
    const newFilter: string =
      filterOrder === 'ascendingDate' ? 'descendingDate' : 'ascendingDate';

    setFilterOrder(newFilter);
  };

  const displayedTableRows: ReactElement[] = [];

  switch (filterOrder) {
    case 'ascendingNumber':
      filterNumberIcon = <SortUpIcon />;

      ascendingNetsuiteIdData.map((deploy: IIpcTableRow) => {
        const row: ReactElement = GenerateRow(deploy);
        return displayedTableRows.push(row);
      });
      break;
    case 'descendingNumber':
      filterNumberIcon = <SortDownIcon />;

      descendingNetsuiteIdData.map((deploy: IIpcTableRow) => {
        const row: ReactElement = GenerateRow(deploy);
        return displayedTableRows.push(row);
      });
      break;
    case 'ascendingDate':
      filterDateIcon = <SortUpIcon />;

      ascendingAssetDateData.map((deploy: IIpcTableRow) => {
        const row: ReactElement = GenerateRow(deploy);
        return displayedTableRows.push(row);
      });
      break;
    case 'descendingDate':
      filterDateIcon = <SortDownIcon />;

      descendingAssetDateData.map((deploy: IIpcTableRow) => {
        const row: ReactElement = GenerateRow(deploy);
        return displayedTableRows.push(row);
      });
      break;
    default:
      break;
  }

  const tableHeaderRow: ReactElement = (
    <div className='table-header-row' key='table-header-key'>
      <div
        onClick={() => filterNumber()}
        className='table-row-item-container hover'
      >
        <p className='table-row-item'>Asset</p>
        {filterNumberIcon}
      </div>
      <div
        onClick={() => filterDate()}
        className='table-row-item-container hover'
      >
        <p className='table-row-item'>Date Created</p>
        {filterDateIcon}
      </div>
      <div className='table-row-item-container'>
        <p className='table-row-item'>Download</p>
      </div>
    </div>
  );

  return (
    <section className='deploys-table--deploys-container'>
      <p className='deploy-ipc-header'></p>
      <Table
        tableHeaderRow={tableHeaderRow}
        displayedTableRows={displayedTableRows}
      />
    </section>
  );
};

export default DeploysTable;
