import { ReactElement } from 'react';

export default function IpcIcon(): ReactElement {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24'>
      <path id='Path_208' d='M0,0H24V24H0Z' fill='none' />
      <path
        id='Path_209'
        d='M20,18a2,2,0,0,0,1.99-2L22,6a2.006,2.006,0,0,0-2-2H4A2.006,2.006,0,0,0,2,6V16a2.006,2.006,0,0,0,2,2H0v2H24V18ZM4,6H20V16H4Z'
        fill='#fff'
      />
    </svg>
  );
}
